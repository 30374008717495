<template>
  <div>
<!--    <headers />-->
    <section class="container">
      <block-title name="重置密码" margin-top="0" />
      <section class="form-container">
        <el-form ref="form" :model="form" :rules="rules" label-width="120px">
<!--          <el-form-item label="旧密码" prop="oldPassword">-->
<!--            <el-input v-model="form.oldPassword" placeholder="旧密码" />-->
<!--          </el-form-item>-->
          <el-form-item label="密码" prop="newPassword">
            <el-input v-model="form.newPassword" show-password autocomplete="new-password" placeholder="密码"  />
          </el-form-item>
          <mi-password-strength-meter :value="isSimplePwd(form.newPassword)" />
          <el-form-item label="确认密码" prop="password2">
            <el-input v-model="form.password2" show-password autocomplete="new-password" placeholder="确认密码" />
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="form.phone"  placeholder="手机号" />
          </el-form-item>
          <el-form-item label="验证码" prop="verificationCode"   >
            <div style="display: flex">
              <el-input v-model="form.verificationCode" placeholder="验证码" />
              <el-button type="primary" plain @click="checkVC" style="margin-left:10px" :disabled="dis">{{ vcTitle }} </el-button>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button class="w-100" type="primary" @click="submit">确认重置</el-button>
          </el-form-item>
<!--          <el-form-item style="text-align: center">-->
<!--            记起密码，-->
<!--            <router-link to="/login">-->
<!--                <el-link type="primary" style="line-height: 1.4">去登录</el-link>-->
<!--            </router-link>-->
<!--          </el-form-item>-->
        </el-form>
      </section>


    </section>
  </div>
</template>

<script>
import BlockTitle from '@/components/BlockTitle' // 标题栏
import { isPhone, checkPassword } from '@/utils/validate'
import service from '@/api/login'
import MiPasswordStrengthMeter from "@/views/home/components/MiPasswordStrengthMeter";
import {JSEncrypt} from "jsencrypt";

export default {
  name: 'ResetPsw',
  components: { BlockTitle, MiPasswordStrengthMeter},
  data() {
    return {
      scenario: 'getback',
      vcTitle: '发送验证码',
      dis: false,
      form: {
        oldPassword: '',
        newPassword: '',
        password: '',
        password2: '',
        verificationCode: '',
        phone:'',
      }
    }
  },
  computed: {
    rules() {
      const checkdPassword = (rule, value, callback) => {
        if (checkPassword(value)) {
          callback()
        } else {
          callback(new Error('密码长度10-16位，且必须包含小写字母、大写字母、数字、特殊符号的三种及以上'))
        }
      }
      const valiPassword = (rule, value, callback) => {
        if (this.form.newPassword === value) {
          callback()
        } else {
          callback(new Error('二次输入的密码不一致'))
        }
      }

      return {
        oldPassword: [
          { required: true, message: '请输入旧密码', trigger: 'change' },
        ],
        phone: [{ required: true, message: '请输入手机号', trigger: 'blur' },],
        verificationCode: [{ required: true, message: '请输入验证码', trigger: 'blur' },],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'change' },
          { validator: checkdPassword, trigger: 'change' }
        ],
        password2: [
          { required: true, message: '请输入确认密码', trigger: 'change' },
          { validator: valiPassword, trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    isSimplePwd: service.isSimplePwd,
    // 提交
    submit() {
      this.$refs.form.validate((validate) => {
        if (validate) {
          //加密
          let jse = new JSEncrypt();
          //公钥
          let publicString = 'MIIDQjANBgkqhkiG9w0BAQEFAAOCAy8AMIIDKgKCAyEAoGwnngViEy2sMI7VL4wIbTgu3oDroenoaIy54KAOj09+XfgAi/lTp/zTQrORKayfZ0J35acDL4xrD2t0SEiFyFuZwB65TGRFC11H/9ACVQ8CQieqZ8Cme96mttO+sEH6mpS+sKZ61XLx5skKs/rTGuH+FYwELkbXzIjGfkotkM8HVhLVwPGHGSHotm+ch3foVRx/S2j6vPfh52z72pC/Tco+6f/Yhkouw87O/AcU8GXKvRxL8aAwdTT7yMzSPp7DQkE/gTFjxRZesIXN5g0LeTZJazaFT3kqOgm/YiH8XEHF0yfneJAu8tLOp0ivkqVgUZT+dPdu9vuNdYi8SMiSaEviwL9mlrCItknaxbus+hCsL90WZ1DSO4aF88BvQrCOsEMwGlIE+T3hb9x+A71TK32N4FBxR1THy0R7J6f31i9emzVsnRKcixYy3GzWfT5VhVG5v0f01KJAWVi8qdDuNBgrb45BsneRthXk6rjFr+bsM18ywPojCD4ksuGW8Ve8yY7kP3DhfMZcJ2YIHdFUM2NOLJFp3RWBg7ZHOm/fQdKVJUunKYxbnwRw6OTtA6JobQ/5F/RbFrtjgBs9gi4riP0LKdbhOMtKMOU6cS8Mq0MJpU6RWcJ+peYyVtieMjtbbsCrmMRFgigg9+GePaYVCkNXmBvbt8em49lnWNK4U+HY7NZh/W2b5D6pOQYlr0flV+MTRoI5rDX1j96jlv0WymXP2t7t6xJdlwtDhnwShcPPQxpys7Z1JlSAi6AFpMNw/VBx9IMG2y3av6nZIoF/EKov3Crhv7MDb9rjpZURuUvF/z+tCuC1jfYNEOHqTnSf+QVaNYNxq16DrViPC4q1kUud8Cuu06vNnUzd5FvRFCHe/nXnXMXZCC6EjtP7epDYa9xkSEhtgMjyJ4ae5XrICT0t/jnhKCuDTgNBCQZBkEvodnulB/fisMfJM8e/k+TPXhhe63WNa2piAaidS19rfARoMqQJeUxM3JdcWEjWm5qmy8f2nU66vlepAhkkdGdRqV0mE6Z70GUB+FmzI9tbATk/IIqTTAyf22IJ+0zCBOkCAwEAAQ==';
          jse.setPublicKey(publicString);
          let password = jse.encrypt(this.form.oldPassword);
          let newPassword = jse.encrypt(this.form.password2);
          let params = {
            id: this.$store.state.user.id,
            // password: password,
            phone: this.form.phone,
            newPassword: newPassword,
            verificationCode: this.form.verificationCode,
          }
          service.resetPSW({...params}).then(res => {
            if(res.success){
              this.$alert('密码重置成功，请牢记您的密码，建议周期性更换密码。', '重置成功', {
                type: 'success',
                confirmButtonText: '去登录',
                showClose: false,
                callback: action => {
                  this.$router.push('/login')
                }
              })
            }else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },

    async checkVC() {
      if (!this.form.phone) {
        return this.$message.warning('请填写手机号');
      }
      try {
        let result = await service.checkPhone({sendPhone: this.form.phone});
        if (!result.success) {
          return this.$message.error(result.message)
        }
      } catch (e) {
        return e
      }

      try {
        let params={
          sendPhone: this.form.phone,
          sendFlag: 1
        }
        let result = await service.sendCheckCode({...params})
        if (!result.success) {
          return this.$message.error(result.message ? result.message : '验证码验证失败');
        }
      } catch (e) {
        return e
      }
      this.dis = true;
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.vcTitle = TIME_COUNT;
        this.timer = setInterval(() => {
          if (this.vcTitle > 0 && this.vcTitle <= TIME_COUNT) {
            this.vcTitle--;
          } else {
            this.dis = false;
            this.vcTitle = '发送验证码';
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 1000px;
  margin: 30px auto;
}
// .steps {
//   margin: 0 auto 50px;
// }
.form-container {
  width: 600px;
  margin: 0 auto;
}

</style>
