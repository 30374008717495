<template>
  <div class="meter">
    <div class="meter-item meter-item-weak" :class="{active: value && (value <= 1)}">弱</div>
    <div class="meter-item meter-item-normal" :class="{active: value && (2 <= value && value < 4)}">中</div>
    <div class="meter-item meter-item-strong" :class="{active: value && (4 <= value)}">强</div>
    <!--{{value}}-->
  </div>
</template>

<script>

  /**
   * 密码强度指示器
   *
   * @author puzhongqiang
   */
  export default {
    props: {
      value: Number,
    }
  }
</script>

<style scoped lang="scss">


  $meterRadius: 7px;

  .meter::v-deep  {margin:10px; text-align: center}
  .meter>.meter-item:first-child {border-radius: $meterRadius 0 0 $meterRadius;}
  .meter>.meter-item:last-child {border-radius:  0 $meterRadius $meterRadius 0;}
  .meter-item {
    display: inline-block;
    width: 90px;
    line-height: 17px;
    font-size: 12px;
    text-align: center;
    font-weight: bold;
    color:white;
    margin: 1px;
    opacity: 0.05;
    transition: 0.5s;
  }
  .meter-item.active { opacity: 1}
  .meter-item-weak {background-color: red; }
  .meter-item-normal {background-color: goldenrod; }
  .meter-item-strong {background-color: limegreen; }
</style>
